import tools from "../../../extends/tools";
export default {
    dataInit: null,
    action: '',
    goodsList: [],
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    saveData: {
        name: '',
        goods: ''
    },
    showData: {}
}