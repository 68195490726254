import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    init() {
        //获取数据源
        apis.getGoodsGroupsSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.getGoodsGroupsDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }
        //
        if (this.$route.query.borrow_id > 0) {
            apis.borrowDetail({
                id: this.$route.query.borrow_id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initDataBorrow(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }

    },

    initDataBorrow(data) {
        let goods = [];
        // data.goods_info.forEach((item, key) => {
        //     for (let i in item) {
        //         if (i === 'price' || i === 'subtotal') {
        //             item[i] = tools.setPrice(item[i]);
        //         }
        //     }
        //     item.sum = item.subtotal;
        //     item.retail_price = item.price;
        //     item.id = item.details_id;
        //     item.name = item.goods_name;
        //     item.unit = item.goods_unit_name;
        //     item.specification_code = item.product_code;
        //     item.pic = item.specifications_pic;
        //     item.dispose_info = item.specifications.join(',');

        //     goods.push(item);
        // });
        this.goodsList = goods;

        this.saveData = {
            warehouse_id: data.warehouse_id,
            number: data.number,
            supplier_id: data.supplier_id,
            user_id: data.user_id,
            business_date: data.business_date,
            additional_amount: tools.setPrice(data.additional_amount),
            additional_amount_copy: tools.setPrice(data.additional_amount),
            settlement_method: data.settlement_method,
            remarks: data.remarks,
            payment_amount: tools.setPrice(data.payment_amount),
            payment_amount_copy: tools.setPrice(data.payment_amount),
            enclosure: data.enclosure,
            goods: goods,
            status: data.status,
            isBorrow: true,
            borrowId: data.id
        }
        this.showData = data;
        this.changeSum();
    },
    initData(data) {
        let goods = [];
        data.value.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.sum = item.subtotal;
            item.retail_price = item.price;
            item.id = item.details_id;
            item.goods_specifications_id = item.skuid;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.specifications_pic;
            item.dispose_info = item.sku.join(',');

            goods.push(item);
        });
        this.goodsList = goods;

        this.saveData = {
            name: data.name
        }
        this.changeSum();
    },
    removeLine(key) {
        // if (this.goodsList.length <= 1) {
        //     return;
        // }
        this.goodsList.splice(key, 1);
        this.changeSum();
    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    fleshList() {

    },
    changePrice(item) {
        item.sum = tools.toDecimal2(item.retail_price * item.num);
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.sumData.allSumPrice = 0;
        this.sumData.sumPrice = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
            console.log((item.retail_price));
            this.sumData.sumPrice += parseFloat(item.retail_price) * item.num;

        });
        this.sumData.allSumPrice = tools.toDecimal2(this.sumData.sumPrice + parseFloat(this.saveData.additional_amount_copy));
        this.sumData.sumPrice = tools.toDecimal2(this.sumData.sumPrice);
    },
    saveSelect(selectData) {
        if (selectData.length <= 0) {
            return;
        }
        selectData.forEach((item, key) => {
            let obj = {};
            obj.specification_code = item.specification_code;
            obj.pic = item.pic;
            obj.name = item.goods_info.name;
            obj.dispose_info = item.dispose_info.join(',');
            obj.unit = item.goods_info.unit_info.name;
            obj.date = '';
            obj.num = 1;
            obj.retail_price = tools.setPrice(item.retail_price);
            obj.sum = obj.retail_price;
            obj.goods_specifications_id = item.id;
            //判断是否有有相同的
            let flag = true;
            this.goodsList.forEach((i, k) => {
                if (!!i.goods_specifications_id && item.id === i.goods_specifications_id) {
                    i.num = i.num + 1;
                    i.sum = tools.toDecimal2(i.retail_price * i.num);
                    flag = false;
                    this.$message({
                        type: 'error',
                        message: '有相同商品，已合并到列表中'
                    })
                }
            });
            //
            if (flag) {
                this.goodsList.push(obj);
            }
        });

        this.changeSum();
        // console.log(this.goodsList);
    },
    shareExit() {
        this.createShareFlag = false;
    },
    pickGoods() {
        if (this.saveData.warehouse_id === '') {

            // this.$message({
            //     type: 'error',
            //     message: '请先选择一个仓库'
            // });
            // return;
        }

        this.createShareFlag = true;
    },
    save(status) {
        this.saveData = {
            name: this.saveData.name
        };
        //转换列表
        let goods = [];

        this.goodsList.forEach((item, key) => {
            let obj = {
                skuid: item.goods_specifications_id,
                num: item.num
            };
            goods.push(obj);
        });

        //
        this.saveData.goods = JSON.stringify(goods);

        let loader = tools.loading(null, this);
        //获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            this.saveData.id = id;
            apis.updateGoodsGroups(this.saveData, 1).then(res => {
                if (tools.msg(res, this)) {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                }
                tools.loading(loader);
            }).catch(err => {
                tools.err(err, this);
                tools.loading(loader);
            });

            return;
        }

        apis.addGoodsGroups(this.saveData, 1).then(res => {
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '添加成功！'
                })
                this.$router.push({
                    path: '/goods/combination'
                });
            }
            tools.loading(loader);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader);
        });
    }
}